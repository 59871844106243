var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_vm._m(0),_c('div',{staticClass:"bg py-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(1),_c('div',{staticClass:"content"},[_c('v-date-picker',{staticClass:"w-100 border-0",model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)])])]),_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('div',{staticClass:"content-container p-3"},[_c('div',{staticClass:"top-content"},[_vm._m(2),_c('p',{staticClass:"small text-muted"},[_vm._v(" All the approved appointment will be listed here ")]),(_vm.isLoadingUpcomming)?_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"spinner-border text-secondary",staticStyle:{"width":"1.5rem","height":"1.5rem"},attrs:{"role":"status"}})]):_vm._e(),_vm._l((_vm.upcommingApp(_vm.upcommingData)),function(data,idx){return _c('div',{key:idx,staticClass:"list-container mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-2"},[_c('h5',{staticStyle:{"margin-top":"8px"}},[_vm._v(" "+_vm._s(_vm.getDateTime(data.appointment_date_time))+" ")])]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"\n                            d-flex\n                            justify-content-between\n                            align-items-center\n                          "},[_c('div',{staticClass:"\n                              profile\n                              d-flex\n                              justify-content-between\n                              align-items-center\n                            "},[(
                                data.user_fullname &&
                                data.user_fullname.length > 0
                              )?_c('div',{staticClass:"\n                                d-flex\n                                justify-content-center\n                                align-items-center\n                                img-container\n                                text-white\n                                rounded-circle\n                              "},[_vm._v(" "+_vm._s(_vm.helper.snakeToTitleCase(data.user_fullname[0]))+" ")]):_c('div',{staticClass:"\n                                d-flex\n                                justify-content-center\n                                align-items-center\n                                img-container\n                                text-white\n                                rounded-circle\n                              "},[_c('i',{staticClass:"ri-user-line align-middle"})]),(data.user_fullname)?_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.helper.snakeToTitleCase(data.user_fullname))+" ")]):_vm._e()]),_c('div',[_c('p',{staticClass:"mb-0 mr-1"},[_vm._v(_vm._s(data.user_phone))])])]),_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('div',{staticClass:"remarks"},[_c('p',{staticClass:"m-0 small text-muted"},[_vm._v("REMARK")]),_c('p',{staticClass:"m-0 small"},[_vm._v(" "+_vm._s(data.remark)+" ")])]),_c('div',[(data.status)?_c('div',{class:data.status == 'approved'
                                  ? 'badge badge-success rounded-pill mr-1'
                                  : 'badge badge-danger rounded-pill mr-1'},[_vm._v(" "+_vm._s(_vm.helper.snakeToTitleCase(data.status))+" ")]):_vm._e()])])])])])])])})],2),_c('div',{staticClass:"bottom-content py-3"},[_vm._m(3),_c('p',{staticClass:"small text-muted"},[_vm._v(" Call the user to confirm or decline ")]),_c('div',{staticClass:"filter-container mb-3"},[_c('button',{class:_vm.selectedFilter == 'all'
                      ? 'btn btn-sm btn-filter mr-2 active'
                      : 'btn btn-sm btn-filter mr-2',attrs:{"type":"button"},on:{"click":_vm.getAllApp}},[_vm._v(" All Appointment ")]),_c('button',{staticClass:"btn btn-sm btn-filter mr-2",class:_vm.selectedFilter == 'approved'
                      ? 'btn btn-sm btn-filter mr-2 active'
                      : 'btn btn-sm btn-filter mr-2',attrs:{"type":"button"},on:{"click":_vm.getAccept}},[_vm._v(" Approved ")]),_c('button',{staticClass:"btn btn-sm btn-filter",class:_vm.selectedFilter == 'rejected'
                      ? 'btn btn-sm btn-filter mr-2 active'
                      : 'btn btn-sm btn-filter mr-2',attrs:{"type":"button"},on:{"click":_vm.getReject}},[_vm._v(" Rejected ")])]),(_vm.isProcessing)?_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"spinner-border text-secondary",staticStyle:{"width":"1.5rem","height":"1.5rem"},attrs:{"role":"status"}})]):_vm._e(),_vm._l((_vm.filterNewReq(_vm.datasource)),function(data,idx){return _c('div',{key:idx,staticClass:"list-container mb-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-2"},[_c('h5',{staticStyle:{"margin-top":"8px"}},[_vm._v(" "+_vm._s(_vm.getDateTime(data.appointment_date_time))+" ")])]),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"\n                            d-flex\n                            justify-content-between\n                            align-items-center\n                          "},[_c('div',{staticClass:"\n                              profile\n                              d-flex\n                              justify-content-between\n                              align-items-center\n                            "},[(
                                data.user_fullname &&
                                data.user_fullname.length > 0
                              )?_c('div',{staticClass:"\n                                d-flex\n                                justify-content-center\n                                align-items-center\n                                img-container\n                                text-white\n                                rounded-circle\n                              "},[_vm._v(" "+_vm._s(_vm.helper.snakeToTitleCase(data.user_fullname[0]))+" ")]):_c('div',{staticClass:"\n                                d-flex\n                                justify-content-center\n                                align-items-center\n                                img-container\n                                text-white\n                                rounded-circle\n                              "},[_c('i',{staticClass:"ri-user-line align-middle"})]),(data.user_fullname)?_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.helper.snakeToTitleCase(data.user_fullname))+" ")]):_vm._e()]),_c('div',[_c('p',{staticClass:"mb-0 mr-1"},[_vm._v(_vm._s(data.user_phone))])])]),_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('div',{staticClass:"remarks"},[_c('p',{staticClass:"m-0 small text-muted"},[_vm._v("REMARK")]),_c('p',{staticClass:"m-0 small"},[_vm._v(" "+_vm._s(data.remark)+" ")])]),(!data.status || data.status == 'pending')?_c('div',{staticClass:"text-right",staticStyle:{"width":"30%"}},[_c('button',{staticClass:"btn btn-sm btn-danger mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleRejectApp(data)}}},[_vm._v(" Reject ")]),_c('button',{staticClass:"btn btn-sm btn-success mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleAcceptApp(data)}}},[_vm._v(" Accept ")])]):_c('div',[_c('div',[(data.status)?_c('button',{class:data.status == 'approved'
                                    ? 'btn btn-info btn-sm mr-1'
                                    : '',on:{"click":function($event){return _vm.generateGCalendarLink(data)}}},[_vm._v(" Sync to Google Calendar ")]):_vm._e()])])])])])])])])})],2)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n      bg-danger\n      position-absolute\n      d-flex\n      justify-content-center\n      align-items-center\n      top-0\n      w-100\n      top-title\n    "},[_c('div',{staticClass:"container p-3 text-center"},[_c('h4',{staticClass:"m-0 text-white"},[_vm._v("Appointment Management")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 align-items-center mb-3"},[_c('i',{staticClass:"ri-calendar-line mr-2 align-middle"}),_c('h6',{staticClass:"m-0"},[_vm._v("Calendar")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 align-items-center mb-1"},[_c('i',{staticClass:"ri-checkbox-line mr-2 align-middle"}),_c('h6',{staticClass:"m-0"},[_vm._v("Upcoming")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 align-items-center mb-1"},[_c('i',{staticClass:"ri-list-check mr-2 align-middle"}),_c('h6',{staticClass:"m-0"},[_vm._v("Appointment Request")])])}]

export { render, staticRenderFns }