<template>
  <div>
    <!-- <customer-view v-if="userRole == 'customer'" />
    <corporate-view v-else-if="userRole == 'pharmacist'" />
    <div class="p-5 text-center" v-else>
      <p>Access not allowed</p>
    </div> -->

    <corporate-view v-if="$route.query.roletype && $route.query.roletype == 'pharmacist'" />
    <customer-view v-else />
  </div>
</template>

<script>
import CorporateView from "@/components/manage-appointment/Corporate";
import CustomerView from "@/components/manage-appointment/Customer";
import { mapGetters } from "vuex";

export default {
  components: {
    CorporateView,
    CustomerView,
  },
  computed: {
    ...mapGetters(["currentCustomer"]),
    userRole() {
      return this.currentCustomer &&
        this.currentCustomer.user &&
        this.currentCustomer.user.role
        ? this.currentCustomer.user.role.name.toLowerCase()
        : "";
    },
  },
};
</script>