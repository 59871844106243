<template>
  <div class="bg">
    <div class="top-links">
        <div class="logo text-center d-sm-none d-block">
            <router-link to="/">
                <img src="/img/logo_selcare_512.png" class="home-logo" alt="" loading="lazy">
            </router-link>
        </div>
    </div>

    <loading-spinner v-if="isLoading" />
    <div v-else-if="showAppointmentForm">
      <div class="main-appointment mb-5">
          <!-- <div class="container pt-2">
              <div class="main-heading">
                  <h2>Clinic / Dental Appointment</h2>
              </div>
              <div class="main-description">
                  <p>
                      For appointment, the staffs will contact you for confirmation of appointment slot.
                      Your request is not confirmed until a confirmation response is given by the staff.
                  </p>
              </div>
              <div class="main-banner d-none d-md-block shadow-sm">
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                      <div class="carousel-inner">
                          <div class="carousel-item active">
                              <img class="d-block w-100" src="/img/appointment_1.jpeg" alt="First slide">
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src="/img/appointment_2.jpeg" alt="First slide">
                          </div>
                      </div>
                      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                      </a>
                  </div>
              </div>
          </div> -->
          <div class="container pt-2">
            <div class="row">
              <div class="col-md-6 col sm-12  d-md-block d-sm-none d-none">
                <div class="p-3">
                    <img class="d-block w-100" src="/img/appointment_2_2.jpg" />
                    <img class="d-block w-100" src="/img/appointment_1_2.jpg" />
                    <p class="py-3">
                        Note: You will be contacted for the confirmation of your appointment slot. 
                        Your request is not confirmed until a response is given by the clinic personnel.
                    </p>
                </div>
              </div>
              <div class="col-md 6 col-sm-12">
                <div class="container mb-4 text-center">
                  <h5 style="color:#a81a17; font-weight: bold;">Book An Appointment With Us!</h5>
                </div>
                <div
                  class="form-container border border-grey rounded bg-white p-4 mx-auto"
                >
                  <loading-spinner
                    class="position-absolute w-100 h-100"
                    text="Submitting your request"
                    :style="{
                      top: 0,
                      left: 0,
                      backgroundColor: '#00000033',
                      zIndex: 'unset',
                    }"
                    v-if="isSaving"
                  />
                  <h6 class="border-bottom border-grey font-weight-bold pb-3 mb-3">
                    Create New Appointment
                  </h6>
                  <div class="form-group">
                    <label for="type" class="small">Select Type</label>
                    <select
                      id="type"
                      class="custom-select custom-select-sm"
                      :class="{ 'is-invalid': invalidFields.includes('type') }"
                      v-model="formModels.type"
                    >
                      <option :value="null" disabled>Select a Type</option>
                      <option value="GP Clinic">GP Clinic</option>
                      <option value="Dental Clinic">Dental Clinic</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="clinic" class="small">Select Clinic</label>
                    <select
                      id="clinic"
                      class="custom-select custom-select-sm"
                      :class="{ 'is-invalid': invalidFields.includes('clinic') }"
                      v-model="formModels.clinic"
                    >
                      <option :value="null" disabled>Select a clinic</option>
                      <option v-for="clinic in clinics" :key="clinic.id" :value="clinic.id">
                        {{ clinic.org_name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <clinic-maps v-if="formModels.type" :clinicType="formModels.type" @clinic-marker-selected="(n) => selectClinicByMap(n)" />
                    <small class="text-muted" style="font-style: italic;" v-else>Please select clinic type to see the map.</small>
                  </div>
                  <div class="form-group">
                    <label for="appointment_date_time" class="small">
                      Preferred Date &amp; Time
                    </label>
                    <v-date-picker
                      v-model="formModels.appointment_date_time"
                      :popover="{
                        placement: 'bottom-start',
                        visibility: 'click',
                        positionFixed: false,
                        hideIndicator: true,
                      }"
                      :min-date="minDateSelection"
                      mode="datetime"
                      :valid-hours="{
                        min: 9,
                        max: 17,
                      }"
                      >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          type="text"
                          class="form-control form-control-sm bg-white"
                          :class="{
                            'is-invalid': invalidFields.includes('appointment_date_time'),
                          }"
                          id="appointment_date_time"
                          placeholder="Select your preferred date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly
                        />
                      </template>
                    </v-date-picker>
                  </div>
                  <div v-if="!currentCustomer" class="form-group">
                    <label for="remark" class="small">Name</label>
                    <input
                        type="text"
                        class="form-control form-control-sm bg-white"
                        placeholder="Insert your name"
                        v-model="formModels.guest_name"
                      />
                  </div>
                  <div class="form-group">
                    <label for="remark" class="small">Phone Number</label>
                    <input
                        type="text"
                        class="form-control form-control-sm bg-white"
                        placeholder="Put your phone number"
                        v-model="formModels.phone"
                      />
                  </div>
                  <div v-if="!currentCustomer" class="form-group">
                    <label for="remark" class="small">Email</label>
                    <input
                        type="text"
                        class="form-control form-control-sm bg-white"
                        placeholder="Put your Email"
                        v-model="formModels.guest_email"
                      />
                  </div>
                  <div class="form-group">
                    <label for="remark" class="small">Remark</label>
                    <textarea
                      class="form-control form-control-sm"
                      :class="{ 'is-invalid': invalidFields.includes('remark') }"
                      id="remark"
                      rows="5"
                      placeholder="Enter a description"
                      v-model="formModels.remark"
                    ></textarea>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <button
                      class="btn btn-cancel"
                      :class="{ invisible: appointments.length == 0 }"
                      @click="showAppointmentForm = false"
                    >
                      Cancel
                    </button>
                    <button class="btn btn-submit" @click="submit()">
                      Request Appointment
                    </button>
                  </div>
                </div>
                <div v-if="!currentCustomer" class="d-flex mx-auto justify-content-end pt-3" style="max-width: 400px;">
                    <router-link to="/auth/login" style="color: #a81a17;">
                      <small><i><u>
                      Click to login as registered user.
                      </u></i></small>
                    </router-link>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else class="list-container mx-auto">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <button
            class="btn btn-link"
            :class="
              currentListType == 'current'
                ? 'text-dark font-weight-bold'
                : 'text-muted'
            "
            @click="currentListType = 'current'"
          >
            Current
          </button>
          <button
            class="btn btn-link"
            :class="
              currentListType == 'past'
                ? 'text-dark font-weight-bold'
                : 'text-muted'
            "
            @click="currentListType = 'past'"
          >
            Past
          </button>
        </div>
        <button class="btn border bg-white" @click="showAppointmentForm = true">
          New Appointment
          <i class="fas fa-plus ml-2" />
        </button>
      </div>
      <div class="py-4">
        <div
          class="list-items"
          v-for="appointment in computedAppointments"
          :key="appointment.id"
        >
          <div class="font-weight-500 item-datetime">
            <p class="mb-0">
              {{ convertDate(appointment.appointment_date_time) }}
            </p>
            <p class="mb-0">
              {{ convertTime(appointment.appointment_date_time) }}
            </p>
          </div>
          <div class="item-info">
            <p class="font-weight-500 mb-0">
              {{ appointment.corporate_organisation.org_name }}
            </p>
            <p class="small text-muted mb-0">REMARK</p>
            <p class="small mb-0 w-100 flex-wrap">{{ appointment.remark }}</p>
          </div>
          <div class="item-status">
            <p class="mb-2">
              {{ appointment.corporate_organisation.org_phone_num }}
            </p>
            <p class="text-right mb-0">
              <span
                class="text-capitalize"
                :class="`pill pill-${appointment.status}`"
              >
                {{ appointment.status }}
              </span>
            </p>
          </div>
        </div>
        <div v-if="!currentCustomer" class="d-flex mx-auto justify-content-end pt-3">
            <router-link to="/auth/login" style="color: #a81a17;">
              <small><i><u>
              Click to login as registered user.
              </u></i></small>
            </router-link>
        </div>
        <div
          class="list-items justify-content-center text-muted font-italic"
          v-if="computedAppointments.length == 0"
        >
          <small>No {{ currentListType }} appointment available</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import Swal from "sweetalert2";
import API from "@/utils/API";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";
import ClinicMaps from "../mapbox/ClinicMaps.vue";

export default {
  components: {
    LoadingSpinner,
    ClinicMaps
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      showAppointmentForm: true,
      appointments: [],
      clinics: [],
      allClinic: [],
      invalidFields: [],
      minDateSelection: Moment()
        .startOf("day")
        .add(2, "days")
        .add(9, "hours")
        .toISOString(),
      formModels: {
        type: null,
        clinic: null,
        appointment_date_time: null,
        remark: "",
        guest_name: null,
        phone: null,
        guest_email: null,
      },
      currentListType: "current",
    };
  },
  watch: {
    showAppointmentForm: {
      handler() {
        if (this.showAppointmentForm) {
          Object.keys(this.formModels).forEach((key) => {
            if (key != "appointment_date_time") {
              this.formModels[key] = null;
            } else {
              this.formModels[key] = this.minDateSelection;
            }
          });
        }
      },
      immediate: true,
    },
    "formModels.type": {
      handler() {
        const newData = this.allClinic.filter(
          (x) => !x.org_name.toLowerCase().includes("test")
        );
        if (this.formModels.type == "Dental Clinic") {
          return (this.clinics = newData.filter((x) =>
            x.org_name.toLowerCase().includes("selcare dental")
          ));
        }

        this.clinics = newData.filter(
          (x) => x.org_name.toLowerCase().includes("selcare clinic")
        );
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["currentCustomer"]),
    computedAppointments() {
      let _appointments = JSON.parse(JSON.stringify(this.appointments));

      _appointments = _appointments.filter((apt) =>
        this.currentListType == "current"
          ? Moment(apt.appointment_date_time).valueOf() > Moment().valueOf()
          : Moment(apt.appointment_date_time).valueOf() < Moment().valueOf()
      );

      _appointments.sort((a, b) => {
        const aDatetime = Moment(a.appointment_date_time).valueOf();
        const bDatetime = Moment(b.appointment_date_time).valueOf();

        if (this.currentListType == "current") {
          return aDatetime > bDatetime;
        } else {
          return aDatetime < bDatetime;
        }
      });

      return _appointments;
    },
  },
  methods: {
    convertDate(datetime) {
      return Moment(datetime).format("D MMMM YYYY");
    },
    convertTime(datetime) {
      return Moment(datetime).format("h:mm a");
    },
    async submit() {
      this.invalidFields = [];

      if(this.currentCustomer){
        this.formModels.guest_name = this.formModels.guest_email =  "-";
      }

      if (Object.values(this.formModels).some((value) => !value)) {
        Object.keys(this.formModels).forEach((key) => {
          if (!this.formModels[key]) {
            this.invalidFields.push(key);
          }

          Swal.fire({
            icon: "error",
            html: "<p class='mb-0'>All fields are required</p>",
            customClass: {
              popup: "swal2-default",
            },
          });
        });

        return;
      } else {
        Swal.fire({
          title: "<h5 class='mb-0'>Are you sure?</h5>",
          html: "<p class='mb-0 small'>Send appointment request</p>",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          buttonsStyling: false,
          reverseButtons: true,
          customClass: {
            popup: "swal2-default",
            confirmButton: "btn btn-success px-5 mx-2",
            cancelButton: "btn btn-white border px-5 mx-2",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.isSaving = true;

            const body = {
              corporate_organisation: this.formModels.clinic,
              remark: this.formModels.remark,
              appointment_name: "Doctor's appointment",
              status: "pending",
              appointment_date_time: this.formModels.appointment_date_time,
              customer: this.currentCustomer? this.currentCustomer.user.customer.id : null, 
              user_fullname: this.currentCustomer? this.currentCustomer.user.username : this.formModels.guest_name,
              user_email: this.currentCustomer? this.currentCustomer.user.email : this.formModels.guest_email,
              user_phone: this.formModels.phone,
              is_guest: !this.currentCustomer ? true : false,
            };

            try {
              const postAppointment = await API.post("/appointments", body);

              this.appointments.push(postAppointment.data);

              console.log(this.appointments);

              Swal.fire({
                icon: "success",
                title: "<h5 class='mb-0'>Request submitted!</h5>",
                html: "<p class='mb-0 small'>Thanks for submitting the appointment. Selcare HQ will call you after this.</p>",
                customClass: {
                  popup: "swal2-default",
                },
              }).then(() => {
                this.showAppointmentForm = false;
              });
            } catch (error) {
              Swal.fire({
                icon: "error",
                html: "<p class='mb-0'>Error submitting request</p>",
                customClass: {
                  popup: "swal2-default",
                },
              });
            } finally {
              this.isSaving = false;
            }
          }
        });
      }
    },
    async getAppointmentList() {
      try {
        let _appointments = null;
        if(this.currentCustomer){
          _appointments = await API.get(
            `/appointments?customer=${this.currentCustomer.user.customer.id}&_sort=appointment_date_time`
          );
        }

        if (_appointments && _appointments.data) {
          this.appointments = _appointments.data;

          if (this.appointments.length > 0) {
            this.showAppointmentForm = false;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        return;
      }
    },
    async getClinicList() {
      try {
        const count = await API.get(
          `/corporate-organisations/count?_sort=org_name`
        );
        const _clinics = await API.get(
          `/corporate-organisations?_sort=org_name&_limit=${count.data}`
        );

        if (_clinics.data) {
          this.allClinic = [
            ..._clinics.data.filter(x => x.org_name && x.org_name.toLowerCase().includes('selcare clinic')), 
            ..._clinics.data.filter(x => x.org_name && x.org_name.toLowerCase().includes('selcare dental'))
          ];

          //another filter - exclude any clinic that ...
          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('hq'))
          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('sri rampai'))
          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('worldwide'))
          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('world wide'))

          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('selcare clinic usj sentral'))
          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('selcare clinic jaya one'))
          this.allClinic = this.allClinic.filter(x => x.org_name && !x.org_name.toLowerCase().includes('selcare clinic desa mentari'))
        }
      } catch (error) {
        console.error(error);
      } finally {
        return;
      }
    },
    selectClinicByMap(_clinicName) {
      this.formModels.clinic = this.allClinic.find(x => x.org_name.toLowerCase() == _clinicName.toLowerCase())?.id;
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.getAppointmentList();

    await this.getClinicList();

    this.isLoading = false;
  },
};
</script>

<style scoped>
.bg {
  width: 100%;
  min-height: calc(100vh - 73px);
  padding: 3rem 5%;
  overflow-y: auto;
  overflow-x: hidden;
  background-image: url("~@/assets/Vector.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #344054;
}

.form-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.border-grey {
  border-color: #e4e7ec !important;
}

.form-group {
  margin-bottom: 1.5rem;
}

::placeholder {
  opacity: 0.7;
}

.btn-submit {
  background-color: #f97066;
  color: #ffffff;
}

.btn-cancel {
  background-color: #ffffff;
  color: #667085;
}

.btn {
  border-radius: 0.5rem;
  font-size: 0.8em;
  text-decoration: none;
}

.list-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.list-items {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  width: 100%;
  overflow: hidden;
}

.pill {
  font-size: 0.8em;
  border-radius: 1.5rem;
  font-weight: 500;
  padding: 0.15rem 0.75rem;
}

.pill-pending {
  background-color: #fef0c7;
  color: #dc6803;
}

.pill-approved {
  background-color: #d1fadf;
  color: #039855;
}

.pill-rejected {
  background-color: #dedede;
  color: #919995;
}

.item-info {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.item-datetime {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.item-status {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

/* Main Nurse Booking Page */
.main-appointment {
    
}
.main-appointment .main-heading{
    text-align: center;
}
.main-appointment .main-heading h2,h3{
    color: #a91a17;
    font-weight: bolder;
}
.main-appointment .main-description{
    text-align: center;
}
.main-appointment .main-description p{
    font-weight: 400;
}
.main-appointment .main-banner{
    border: 2px solid #afafaf30;
}
/* Main Nurse Booking Page */

@media (max-width: 499px) {
  .list-items {
    flex-direction: column;
  }

  .item-info {
    padding: 1rem 0;
    flex-basis: 100%;
  }

  .item-datetime {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item-status {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/** Media Queries */
@media (max-width: 575px) {

.top-links {
    margin-bottom: 20px;
}
.top-links > a {
    float: left;
    margin-top: 10px;
}
.top-links .logo {
    /* margin-right: 20px; */
}
.top-links .home-logo {
    height: 75px;
}
}
</style>