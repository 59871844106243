<template>
  <div class="loading-spinner">
    <div
      class="
        px-5
        py-3
        bg-white
        rounded
        shadow-sm
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <span class="spinner-border spinner-border-sm mr-2"></span>
      <small>{{ text || "Loading..." }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}
</style>