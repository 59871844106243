<template>
  <div class="">
    <div
      class="
        bg-danger
        position-absolute
        d-flex
        justify-content-center
        align-items-center
        top-0
        w-100
        top-title
      "
    >
      <div class="container p-3 text-center">
        <h4 class="m-0 text-white">Appointment Management</h4>
      </div>
    </div>
    <div class="bg py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex w-100 align-items-center mb-3">
                  <i class="ri-calendar-line mr-2 align-middle"></i>
                  <h6 class="m-0">Calendar</h6>
                </div>
                <div class="content">
                  <v-date-picker
                    class="w-100 border-0"
                    v-model="selectedDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-sm-12">
            <div class="content-container p-3">
              <div class="top-content">
                <div class="d-flex w-100 align-items-center mb-1">
                  <i class="ri-checkbox-line mr-2 align-middle"></i>
                  <h6 class="m-0">Upcoming</h6>
                </div>
                <p class="small text-muted">
                  All the approved appointment will be listed here
                </p>
                <!-- loading -->
                <div class="my-4" v-if="isLoadingUpcomming">
                  <div
                    class="spinner-border text-secondary"
                    style="width: 1.5rem; height: 1.5rem"
                    role="status"
                  ></div>
                  <!-- <p class="small text-secondary mt-2">
                    Searching active e-prescription
                  </p> -->
                </div>
                <!-- end loading -->
                <div
                  class="list-container mb-3"
                  v-for="(data, idx) in upcommingApp(upcommingData)"
                  :key="idx"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row no-gutters">
                        <div class="col-md-2">
                          <h5 style="margin-top: 8px">
                            {{ getDateTime(data.appointment_date_time) }}
                          </h5>
                        </div>
                        <div class="col-md-10">
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            <div
                              class="
                                profile
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div
                                v-if="
                                  data.user_fullname &&
                                  data.user_fullname.length > 0
                                "
                                class="
                                  d-flex
                                  justify-content-center
                                  align-items-center
                                  img-container
                                  text-white
                                  rounded-circle
                                "
                              >
                                {{
                                  helper.snakeToTitleCase(data.user_fullname[0])
                                }}
                              </div>
                              <div
                                v-else
                                class="
                                  d-flex
                                  justify-content-center
                                  align-items-center
                                  img-container
                                  text-white
                                  rounded-circle
                                "
                              >
                                <i class="ri-user-line align-middle"></i>
                              </div>
                              <h6 class="m-0" v-if="data.user_fullname">
                                {{
                                  helper.snakeToTitleCase(data.user_fullname)
                                }}
                              </h6>
                            </div>
                            <div>
                              <p class="mb-0 mr-1">{{ data.user_phone }}</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between mt-3">
                            <div class="remarks">
                              <p class="m-0 small text-muted">REMARK</p>
                              <p class="m-0 small">
                                {{ data.remark }}
                              </p>
                            </div>
                            <div>
                              <div
                                v-if="data.status"
                                :class="
                                  data.status == 'approved'
                                    ? 'badge badge-success rounded-pill mr-1'
                                    : 'badge badge-danger rounded-pill mr-1'
                                "
                              >
                                {{ helper.snakeToTitleCase(data.status) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom-content py-3">
                <div class="d-flex w-100 align-items-center mb-1">
                  <i class="ri-list-check mr-2 align-middle"></i>
                  <h6 class="m-0">Appointment Request</h6>
                </div>
                <p class="small text-muted">
                  Call the user to confirm or decline
                </p>
                <div class="filter-container mb-3">
                  <button
                    type="button"
                    :class="
                      selectedFilter == 'all'
                        ? 'btn btn-sm btn-filter mr-2 active'
                        : 'btn btn-sm btn-filter mr-2'
                    "
                    @click="getAllApp"
                  >
                    All Appointment
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-filter mr-2"
                    @click="getAccept"
                    :class="
                      selectedFilter == 'approved'
                        ? 'btn btn-sm btn-filter mr-2 active'
                        : 'btn btn-sm btn-filter mr-2'
                    "
                  >
                    Approved
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-filter"
                    @click="getReject"
                    :class="
                      selectedFilter == 'rejected'
                        ? 'btn btn-sm btn-filter mr-2 active'
                        : 'btn btn-sm btn-filter mr-2'
                    "
                  >
                    Rejected
                  </button>
                </div>
                <!-- loading -->
                <div class="my-4" v-if="isProcessing">
                  <div
                    class="spinner-border text-secondary"
                    style="width: 1.5rem; height: 1.5rem"
                    role="status"
                  ></div>
                  <!-- <p class="small text-secondary mt-2">
                    Searching active e-prescription
                  </p> -->
                </div>
                <!-- end loading -->
                <div
                  class="list-container mb-3"
                  v-for="(data, idx) in filterNewReq(datasource)"
                  :key="idx"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row no-gutters">
                        <div class="col-md-2">
                          <h5 style="margin-top: 8px">
                            {{ getDateTime(data.appointment_date_time) }}
                          </h5>
                        </div>
                        <div class="col-md-10">
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            <div
                              class="
                                profile
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div
                                v-if="
                                  data.user_fullname &&
                                  data.user_fullname.length > 0
                                "
                                class="
                                  d-flex
                                  justify-content-center
                                  align-items-center
                                  img-container
                                  text-white
                                  rounded-circle
                                "
                              >
                                {{
                                  helper.snakeToTitleCase(data.user_fullname[0])
                                }}
                              </div>
                              <div
                                v-else
                                class="
                                  d-flex
                                  justify-content-center
                                  align-items-center
                                  img-container
                                  text-white
                                  rounded-circle
                                "
                              >
                                <i class="ri-user-line align-middle"></i>
                              </div>
                              <h6 class="m-0" v-if="data.user_fullname">
                                {{
                                  helper.snakeToTitleCase(data.user_fullname)
                                }}
                              </h6>
                            </div>
                            <div>
                              <p class="mb-0 mr-1">{{ data.user_phone }}</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between mt-3">
                            <!-- <div class="remarks" style="width: 70%"> -->
                            <div class="remarks">
                              <p class="m-0 small text-muted">REMARK</p>
                              <p class="m-0 small">
                                {{ data.remark }}
                              </p>
                            </div>
                            <div
                              class="text-right"
                              style="width: 30%"
                              v-if="!data.status || data.status == 'pending'"
                            >
                              <button
                                type="button"
                                class="btn btn-sm btn-danger mx-1"
                                @click="handleRejectApp(data)"
                              >
                                Reject
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm btn-success mx-1"
                                @click="handleAcceptApp(data)"
                              >
                                Accept
                              </button>
                            </div>
                            <div v-else>
                              <div>
                                <!-- <div
                                  v-if="data.status"
                                  :class="
                                    data.status == 'approved'
                                      ? 'badge badge-success rounded-pill mr-1'
                                      : 'badge badge-danger rounded-pill mr-1'
                                  "
                                >
                                  {{ helper.snakeToTitleCase(data.status) }}
                                </div> -->
                                <button
                                  v-if="data.status"
                                  @click="generateGCalendarLink(data)"
                                  :class="
                                    data.status == 'approved'
                                      ? 'btn btn-info btn-sm mr-1'
                                      : ''
                                  "
                                >
                                  Sync to Google Calendar
                              </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import API from "@/utils/API";
import Helper from "@/utils/helper";
import qs from 'qs'
export default {
  name: "appointment",
  data() {
    return {
      selectedDate: new Date(),
      datasource: [],
      selectedFilter: "all",
      helper: Helper,
      isProcessing: true,
      isLoadingUpcomming: true,
      upcommingData: [],
    };
  },
  watch: {
    selectedDate: {
      handler() {
        this.datasource = [];
        this.isLoadingUpcomming = true;
        this.getAppoinment();
      },
      immediate: false,
    },
  },
  methods: {
    getAllApp() {
      this.isProcessing = true;
      this.datasource = [];
      this.selectedFilter = "all";
      this.getAppoinment();
    },
    getAccept() {
      this.isProcessing = true;
      this.datasource = [];
      this.selectedFilter = "approved";
      // this.filterNewReq(this.datasource);
      this.getAppoinment();
    },
    getReject() {
      this.isProcessing = true;
      this.datasource = [];
      this.selectedFilter = "rejected";
      // this.filterNewReq(this.datasource);
      this.getAppoinment();
    },
    async handleAcceptApp(data) {
      this.isLoadingUpcomming = true;
      await API.put(`/appointments/${data.id}`, {
        ...data,
        status: "approved",
      }).then(async (res) => {
        await this.getAppoinment();
      });
    },
    async handleRejectApp(data) {
      this.datasource = this.datasource.filter((x) => x.id != data.id);
      API.put(`/appointments/${data.id}`, {
        ...data,
        status: "rejected",
      }).then(async (res) => {
        await this.getAppoinment();
      });
    },
    upcommingApp(data) {
      const newItem = data;
      let current = moment().format("DD MM YYYY");

      return newItem
        .filter(
          (x) => moment(x.appointment_date_time).format("DD MM YYYY") == current
        )
        .filter((x) => x.status == "approved");
    },
    filterNewReq(data) {
      const newItem = data;
      if (this.selectedFilter == "all") {
        return newItem
          .filter((x) => x.status != "approved")
          .filter((x) => x.status != "rejected");
      } else if (this.selectedFilter == "approved") {
        return newItem.filter((x) => x.status == "approved");
      } else {
        return newItem.filter((x) => x.status == "rejected");
      }
    },
    getDateTime(time) {
      return moment(time).format("h:mm A");
    },
    async getAppoinment() {
      const url = "";
      const searchParams = new URLSearchParams(url);
      searchParams.set("_sort", "appointment_date_time:ASC");
      if (this.selectedDate) {
        let gdate = `${moment(this.selectedDate).format(
          "YYYY-MM-DD"
        )}T00:00:00+08:00`;
        let ldate = `${moment(this.selectedDate).format(
          "YYYY-MM-DD"
        )}T24:00:00+08:00`;

        searchParams.set("appointment_date_time_gt", gdate);
        searchParams.set("appointment_date_time_lte", ldate);
      }

      const res = await API.get(`/appointments?${searchParams.toString()}`);
      if (res.status == 200) {
        setTimeout(() => {
          this.isProcessing = false;
          this.isLoadingUpcomming = false;
          this.datasource = res.data;
          this.upcommingData = res.data;
        }, 300);
      }
    },
    generateGCalendarLink(_data){
      var links = 'https://calendar.google.com/calendar/u/0/r/eventedit?'

      var newDate = new Date(_data.appointment_date_time)
      var isoDate = newDate.toISOString()
      var formattedDate = isoDate.replace(/[^\w\s]/gi, '');

      const query = qs.stringify({
        text:'Selcare Appointment',
        details:'Selcare Appointment from Customer of selcare.com',
        location:'SELCARE CLINIC SDN BHD (Administrative Office), 47A, Jalan Snuker 13/28, Tadisma Business Park, 40100 Shah Alam, Selangor',
        dates: formattedDate + '/' + formattedDate,
        ctz: 'Malaysia'
      }, {
        encodeValuesOnly: true,
      });

      window.open(links + query, '_blank')
    }
  },
  async mounted() {
    await this.getAppoinment();
  },
};
</script>

<style scoped>
.badge {
  font-weight: 400;
  padding: 5px 10px;
}
.bg {
  position: absolute;
  width: 100%;
  display: flex;
  background-image: url("~@/assets/Vector.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 134px);
  overflow: hidden;
  top: 134px;
}

.content-container {
  position: relative;
  display: block;
  height: calc(100vh - 135px);
  overflow: scroll;
}

.img-container {
  position: relative;
  display: block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  background: #f2f4f7;
  color: #475467 !important;
}

::-webkit-scrollbar {
  display: none !important;
}

.btn-filter.active {
  background-color: #fef3f2 !important;
  color: #b42318 !important;
}

.btn-filter {
  color: #667085 !important;
}

@media (max-width: 420px) {
  .bg {
    top: 162px;
  }
}

@media (max-width: 720px) {
  .bg {
    overflow: auto;
  }
  .content-container {
    height: 100% !important;
  }
}
</style>